import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

export const useBlogCardStyles = makeStyles(
  (_theme: Theme) =>
    createStyles({
      card: {
        padding: 0,
        height: (props: { fullHeight: boolean }) =>
          props.fullHeight ? '100%' : 'auto'
      },
      cardAction: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiCardMedia-root': {
          width: '100%'
        }
      },
      cardContent: {
        flexGrow: 1,
        display: 'flex',
        width: '100%',
        flexDirection: 'column'
      },
      apectRatio: {
        '--aspect-ratio': '16/9',
        paddingTop: 'calc((1 / (var(--aspect-ratio))) * 100%)',

        '@supports (aspect-ratio: 1)': {
          aspectRatio: 'var(--aspect-ratio)',
          paddingTop: 'initial'
        }
      }
    }),
  { name: 'BlogCard' }
)
