import Api from '~/utils/Api'

import { getEntityByBrand } from '~/brands'
import {
  HemaxArticle,
  HemaxBaseArticle,
  HemaxCategoory,
  HemaxContent,
  HemaxParams,
  HemaxSearchParams,
  ResponseHemax
} from '~/models/Hemax'

class HemaxService extends Api {
  private siteId = getEntityByBrand({ rv: 6, boat: 7 })

  getSiteId() {
    return this.siteId
  }

  /**
   * Get article by id
   */
  getArticleById = (
    articleId: string,
    params?: { includeAttached: boolean; siteId: number }
  ): Promise<HemaxArticle> =>
    this.get('/api/v1/hemax/article_by_id', {
      params: {
        ...params,
        siteId: this.siteId,
        id: articleId,
        includeAttached: true
      }
    })
  /**
   * Get articles
   */
  getArticles = (params?: HemaxParams): Promise<ResponseHemax<HemaxArticle>> =>
    this.post(`/api/v1/hemax/articles_by_site_id`, {
      data: {
        siteId: this.siteId,
        skip: 0,
        pageSize: 10,
        ...params
      }
    })
  /**
   * Get featured articles
   */
  getFeaturedArticles = (
    params?: HemaxParams
  ): Promise<ResponseHemax<HemaxBaseArticle>> =>
    this.post('/api/v1/hemax/featured_articles', {
      data: {
        siteId: this.siteId,
        skip: 0,
        pageSize: 10,
        ...params
      }
    })
  /**
   * Search articles
   */
  searchArticles = (
    params?: HemaxSearchParams
  ): Promise<ResponseHemax<HemaxBaseArticle>> =>
    this.post(`/api/v1/hemax/search_articles`, {
      data: {
        siteId: this.siteId,
        skip: 0,
        pageSize: 10,
        ...params
      }
    })
  /**
   * Get categories
   */
  getCategories = (
    params?: HemaxParams
  ): Promise<ResponseHemax<HemaxCategoory>> =>
    this.post(`/api/v1/hemax/categories`, {
      data: {
        skip: 0,
        pageSize: 10,
        ...params
      }
    })
  /**
   * Get slugs
   */
  getSlugs = (): Promise<string[]> =>
    this.post(`/api/v1/hemax/slugs_by_site_id`, {
      data: {
        siteId: this.siteId
      }
    })
  /**
   * Get content
   */
  getContent = (id: string | number): Promise<HemaxContent> =>
    this.get(`/api/v1/hemax/content?id=${id}`)
  /**
   * Get content
   */
  getArticlesByCategory = (
    catId: string | number,
    pageSize = 10
  ): Promise<ResponseHemax<HemaxArticle>> =>
    this.get(`/api/v1/hemax/article_by_category`, {
      params: {
        siteId: this.siteId,
        catId,
        pageSize
      }
    })
}

const Hemax = new HemaxService({
  baseURL: process.env.REACT_APP_API,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
})

export default Hemax
