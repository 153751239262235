import Image from 'next/image'
import RouteLink from 'next/link'
import React, { FC } from 'react'

import { DateTime } from 'luxon'

import { Card, CardActionArea, CardContent, CardMedia } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import Paths from '~/utils/paths'

import { HemaxBaseArticle } from '~/models/Hemax'

import Text from '../../shared/Text'
import SimpleCard from '../SimpleCard'

import { useBlogCardStyles } from './blogCard.styles'

interface BlogCardProps {
  data: HemaxBaseArticle
  fullHeight?: boolean
  loading?: boolean
}

const BlogCard: FC<BlogCardProps> = ({ data, loading, fullHeight = true }) => {
  const classes = useBlogCardStyles({ fullHeight })

  return (
    <Card
      component={SimpleCard}
      className={classes.card}
      style={{ pointerEvents: loading ? 'none' : 'auto' }}
    >
      <RouteLink
        href={Paths.newsReviews.buildArticle(
          data.cmsArticleId,
          data.contentName
        )}
        passHref
      >
        <CardActionArea className={classes.cardAction}>
          <CardMedia
            style={{ position: 'relative' }}
            title={data.pageTitle}
            className={classes.apectRatio}
          >
            {loading ? (
              <Skeleton variant="rect" width="100%" height="100%" />
            ) : (
              data.primaryImageUrl && (
                <Image
                  alt={data.pageTitle}
                  title={data.pageTitle}
                  src={data.primaryImageUrl}
                  layout="fill"
                  width={400}
                  height={300}
                  quality={55}
                  objectFit="cover"
                  objectPosition="center"
                  sizes="(max-width: 600px) 300px, 600px"
                />
              )
            )}
          </CardMedia>

          <CardContent className={classes.cardContent}>
            <Text
              mt={0}
              mb={1}
              variant="h5"
              fontFamily="Nunito Sans, sans-serif"
              color="primary"
              fontSize={14}
              fontWeight={400}
            >
              {loading ? (
                <Skeleton variant="rect" width={120} />
              ) : (
                DateTime.fromISO(data.publishDateTimeUtc).toFormat(
                  'EEE d MMM y'
                )
              )}
            </Text>

            <Text
              variant="h6"
              fontSize={20}
              fontFamily="Prompt, sans-serif"
              fontWeight={400}
            >
              {loading ? (
                <Skeleton variant="rect" width={180} />
              ) : (
                data.pageTitle
              )}
            </Text>
          </CardContent>
        </CardActionArea>
      </RouteLink>
    </Card>
  )
}

export default BlogCard
