import React, { FC } from 'react'

import { useQuery } from 'react-query'

import { Box, Grid } from '@material-ui/core'

import BlogCard from '~/components/cards/BlogCard/BlogCard'
import AdsSlot from '~/components/shared/AdSlot'
import Section from '~/components/shared/Section'

import Hemax from '~/services/Hemax'
import Paths from '~/utils/paths'

import { CACHE_KEYS } from '~/constants'

type Props = {
  isPrefetched?: boolean
}

const NewsReviewsSection: FC<Props> = ({ isPrefetched }) => {
  const { data } = useQuery(
    CACHE_KEYS.severalFeaturedArticles,
    () => Hemax.getFeaturedArticles({ pageSize: 3 }),
    {
      enabled: !isPrefetched
    }
  )

  return (
    <Section
      bgcolor="bg.primary.main"
      displayLink
      linkHref={Paths.newsReviews.index}
      title="News & Reviews"
      empty={!data?.items?.length}
      unmountOnEmpty
    >
      <Grid container spacing={2}>
        {data?.items?.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={item.cmsArticleId || index}>
            <BlogCard data={item} />
          </Grid>
        ))}
      </Grid>
      <Box display="flex" justifyContent="center" flexWrap="wrap" mt={9}>
        <AdsSlot
          slotId="gam_banner_pos3"
          sizes={[[728, 90]]}
          sizeMapping={[
            {
              viewport: [1280, 0],
              sizes: [
                [728, 90],
                [970, 90]
              ]
            },
            { viewport: [895, 0], sizes: [[728, 90], 'fluid'] },
            {
              viewport: [0, 0],
              sizes: [[300, 250], [320, 100], [320, 50], [300, 50], 'fluid']
            }
          ]}
          targetingArguments={{ pos: '3', native: 'infeed' }}
        />
      </Box>
    </Section>
  )
}

export default NewsReviewsSection
